<template>
  <div class="Main">
    <div v-if="bShowHome" class="">
      <div v-if="returnMessage" class="msg_overlay text-center">
        <h1 :class="classMessage">
          <i
            class="bi bi-bell"
            style="font-size: 103px; display: block"
            :class="classMessage"
          ></i
          ><br />{{ returnMessage }}
        </h1>
      </div>

      <nav class="navbar sticky-top navbar-dark">
        <img src="/banner_cfp2.png" class="img-fluid" />
        <div class="container-fluid">
          <a class="navbar-brand"
            ><span style="font-size: 0.9rem">Salle en cours :</span>
            <h4 style="color: #fff !important">
              {{ activeRoom }}
            </h4></a
          >
          <div class="float-end mr-3">
            <select
              class="form-select"
              aria-label="Selectionnez une salle"
              v-model="activeRoom"
            >
              <option value="01-Auditorium Berlioz">Auditorium Berlioz</option>
              <option value="02-Auditorium Pasteur">Auditorium Pasteur</option>
              <option value="03-Auditorium Einstein">
                Auditorium Einstein
              </option>
              <option value="04-Joffre 1">Joffre 1</option>
              <option value="05-Joffre AB">Joffre AB</option>
              <option value="06-Antigone 1">Antigone 1</option>
              <option value="07-Antigone 3">Antigone 3</option>
              <option value="08-Rondelet">Rondelet</option>
              <option value="09-Barthez">Barthez</option>
              <option value="10-Studio">Studio</option>
              <option value="12-Auditorium Test">Test</option>
            </select>
          </div>
        </div>
      </nav>
      <div
        class="container-fluid pt-3"
        style="height: 100vh"
        :class="classMode"
      >
        <button
          type="button"
          class="btn btn_myicon_success"
          id="readBarcode"
          @click="showScanner"
        >
          <i class="bi bi-upc-scan" style="font-size: 53px"></i>
        </button>
      </div>
    </div>
    <BarcodeScanner
      v-if="bShowScanner"
      v-on:appendMessage="appendMessage"
      v-on:hideScanner="hideScanner"
      v-bind:modeMessage="modeMessage"
    ></BarcodeScanner>
  </div>
</template>

<script>
import DBR from "../dbr";
import BarcodeScanner from "./BarcodeScanner";
export default {
  name: "Main",
  props: {},
  data() {
    return {
      libLoaded: false,
      bShowScanner: false,
      bShowHome: true,
      returnMessage: false,
      classMessage: false,
      classMode: "green_back",
      outScanMode: false,
      api_url: "https://cfpadmin.urevent.fr/Scanapi/scanning",
      modeMessage: "ENTREE",
      activeRoom: "PAS DE SALLE SELECTIONNEE",
    };
  },
  async mounted() {
    //Load the library on page load to speed things up.
    try {
      DBR.BarcodeReader._bUseFullFeature = true;
      await DBR.BarcodeScanner.loadWasm();
      this.libLoaded = true;
    } catch (ex) {
      alert(ex.message);
      throw ex;
    }
  },
  updated() {},

  components: {
    BarcodeScanner,
  },

  methods: {
    appendMessage(message) {
      this.hideScanner();
      switch (message.type) {
        case "result":
          this.$auth
            .fetch(this.api_url, {
              method: "POST",
              body: {
                scanning_externalid: message.text,
                roomcur: this.activeRoom,
              },
            })
            .then((response) => {
              if (response) {
                if (response.status) {
                  if (response.status === 407) {
                    this.returnMessage = response.message;
                    this.classMessage = "error";
                    setTimeout(() => {
                      this.returnMessage = "";
                      this.showScanner();
                    }, 1000);
                  } else {
                    this.returnMessage = response.message;
                    this.classMessage = "error";
                    setTimeout(() => {
                      this.returnMessage = "";
                      this.showScanner();
                    }, 1000);
                  }
                } else {
                  if (response == 3) {
                      this.returnMessage = "Accès autorisé";
                      this.classMessage = "success";
                      setTimeout(() => {
                        this.returnMessage = "";
                        this.showScanner();
                      }, 1000);
                  } else if (response == 2) {
                    this.returnMessage = "Déjà scanné";
                    this.classMessage = "warning";
                    setTimeout(() => {
                      this.returnMessage = "";
                      this.showScanner();
                    }, 1000);
                  }
                }
              }
            });
          break;
        case "error":
          break;
        default:
          break;
      }
    },

    resetalert() {
      this.returnMessage = false;
      this.classMessage = false;
    },

    showScanner() {
      this.bShowScanner = true;
      this.bShowHome = false;
    },

    hideScanner() {
      this.bShowHome = true;
      this.bShowScanner = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>